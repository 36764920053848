import { useEffect, useState } from "react";
import  FileUploader  from "./components/FileUploader.tsx"
import Modal from 'react-modal';
import {ReactComponent as Cross} from './images/cross.svg'
import "./App.css";
import Button from "./components/Button.tsx";

const fileTypes = ["XLSX", "XLS"];

export default function App() {
  const [file, setFile] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [request, setRequest] = useState({loading: false, error: null, data:null})
  const handleChange = (file) => {
    setFile(file);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if(file){
      const formData = new FormData();
      formData.append('users', file);
      setRequest({...request, loading: true});

      fetch('http://localhost:3000/upload-users', {
        method: 'POST',
        body: formData
      })
        .then(res => res.json())
        .then(data => {
          if (data.error) {
            setRequest({...request, data: data.message, error: data.error})
          } else {
            setRequest({...request, data: data.message, error: null})
          }
          setFile(null)
          openModal();
        })
        .catch(err => {
          setRequest({...request, error: err})
          openModal();
        })
        .finally(()=> {
          // setRequest({...request, loading: false})
        })
    } else {
      openModal();
    }
  }

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  Modal.setAppElement('#root');
  
  useEffect(()=> {
    console.log(file);
  }, [file])

  return (
    <div className="App">
      <h1>Перетащите подготовленный файл</h1>
      <form onSubmit={handleSubmit}>
        <FileUploader
          classes="drop_zone"
          multiple={false}
          handleChange={handleChange}
          name="users"
          label="Загрузите или перетащите файл прямо сюда"
          types={fileTypes}
          fileOrFiles={file}
          onTypeError={() => setFile(null)}
        />
        <p>{file ? <span className="success">{`Файл готов для импорта: ${file.name}`}</span> : <span className="error">Файл для импорта еще не загружен</span>} </p>
        <Button>Импортировать</Button>
      </form>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="modal"
        overlayClassName="overlay"
      >
        {request.error && <p className="error">{request.error}</p>}
        {request.data && <p className="success">{request.data}</p>}
        {!request.data && !request.error && <p className="error">Выберите или перетащите файл с пользователями</p>}
        <button className="modal__close" onClick={closeModal}><Cross/></button>
      </Modal>
    </div>
  );
}
