import { FC } from 'react';
import {
    ButtonWrapper,
  } from './style.ts';


type Props = {
    children?: JSX.Element;
};

const Button:FC<Props> = ({children}) => {
    return (
        <ButtonWrapper>
            <button className="learn-more">
                <span className="circle" aria-hidden="true">
                <span className="icon arrow"></span>
                </span>
                <span className="button-text">{children}</span>
            </button>
      </ButtonWrapper>
    )
}

export default Button;